<template>
    <!--登录-->
    <div class="login">
        <div class="login_content">
            <span class="login_content_title">员工登录</span>
            <div class="login_content_input">
                <input type="text" v-model="staff.userId" placeholder="请输入7位员工号(i+6位数字)">
                <span class="login_content_input_warn"
                      :class="valUseridFormat(staff.userId)?'success':'error'">
                    {{ this.staff.userId.length === 0 ? '*' : (valUseridFormat(staff.userId) ? '√' : '请检查格式') }}
                </span>
            </div>
            <div class="login_content_input">
                <input type="email" v-model="staff.email" placeholder="请输入SAP公司邮箱">
                <span class="login_content_input_warn"
                      :class="valEmailFormat(staff.email)?'success':'error'">
                    {{ this.staff.email.length === 0 ? '*' : (valEmailFormat(staff.email) ? '√' : '请检查格式') }}
                </span>
            </div>
            <button class="login_content_button" @click="login">登录</button>
        </div>

        <div class="rule">
            <p class="title">活动规则及重要提醒</p>
            <ul>
                <li>2024年SAP北分工会秋季家庭主题活动由SAP北京分公司工会举办，仅限2024年7月1日前入职，目前仍在职的SAP北京分公司工会会员参加；</li>
                <li>活动将在第五季龙水凤港生态露营农场举办；报名日期为即日起至9月15日（周日）18:00截止；活动日期为9月22日（星期日）、10月6日（星期日）两场二选一；</li>
                <li>符合报名条件的每位会员，可携带2名家属（不限年龄）参加活动，例如1名成人家属+1名儿童，或2名成人家属，如需邀请更多随行亲友，请按园区要求另行自费购买门票、餐食及意外保险等；</li>
                <li>所有信息收集仅活动管理员及活动相关公司可见，请准确、如实填写信息；参加活动即视为授权工会对各位会员活动现场照片进行保存、编辑、发布、展示；</li>
                <li>项目均自愿参加，儿童需至少1名家长全程陪同；农场体验活动环境条件有限，建议为孩子携带好替换衣服；</li>
                <li>部分项目受天气影响可能调整，按照当天场地方安排而定；</li>
                <li>提醒每一位活动参与者，遵守园区各项规则，听从工作人员指引，文明出行。爱惜园区资源及设备，鸡蛋等易碎物品轻拿轻放，如有损坏，需自行承担，照价赔偿；</li>
                <li>由于SAP公司已为员工购买补充商业保险，因此本次活动仅对注册名额内会员家属提供当日活动意外保险。如携带亲友，请妥善看护；</li>
                <li>活动相关问题请发送邮件至Leqiliao@imprforest.com信箱咨询；活动门票现场领取，现场供应商负责人及联系方式：乐琪-13146133431，袁建清-13910219779；</li>
                <li>本次活动最终解释权归思爱普（中国）有限公司北京分公司工会所有。</li>
            </ul>
        </div>

        <Error v-if="isShow" @close="isShow=false" :message="message"></Error>

    </div>
</template>

<script>
import Error from '../components/error'
import {mapActions} from 'vuex'
import {valUserid, valEmail} from "./valid";

export default {
    name: "login",
    components: {
        Error
    },
    data() {
        return {
            staff: {
                userId: '',
                email: '',
            },
            isShow: false,
            message: ''
        }
    },

    methods: {
        ...mapActions({
            doLogin: 'passport/login',
            setRecord: 'setRecord',
            setStaff: 'setStaff',
        }),
        login() {
            this.doLogin(this.staff).then((result) => {
                this.setStaff({staff_id: result.data.staff.id, email: result.data.staff.email,status:result.data.record.status});

                if (result.data.record) {
                    this.setRecord(result.data.record);

                    this.$router.replace({name: 'checkin'})
                } else {
                    this.$router.replace({name: 'index'})
                }
            }).catch((err) => {
                this.isShow = true
                console.log(err);
                this.message = err.message
            });
        },

        //输入格式判断
        valUseridFormat(input) {
            return valUserid(input);
        },
        valEmailFormat(input) {
            return valEmail(input);
        },
    }
}

</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
//@import '../../styles/mixins.styl'
@import '../assets/mixins.styl'
.error
    color $error

.success
    color green

.login
    width 750px
    min-height 1333px
    background no-repeat url("../assets/image/login_page_bac0.jpg")
    background-size 100%
    //background-color: #69c671;
    //登录框

    .login_content
        width 650px
        height 462px
        background-color white
        box-shadow 0 17px 35px 0 rgba(0, 41, 30, 0.08)
        border-radius 60px
        position absolute
        top 640px
        left 0
        right 0
        margin auto
        z-index 99
        display flex
        flex-direction column
        align-items center

        .login_content_title
            titleSet()
            margin 40px 0 44px
            color #f24b52

        .login_content_input > input
            width 599px
            inputSet()
            margin-bottom 12px

        input::placeholder
            inputPlaceholderSet()

        .login_content_input_warn
            font-size 30px
            line-height 86px
            position absolute
            right 60px

        //color $error

        .login_content_button
            buttonSet()
            margin 17px 0 33px
            color white
            background: linear-gradient(to right, #f24b52, #ff8e26)

.rule
    border-radius 50px 50px 0 0
    padding 40px 70px 20px 70px
    margin 0 auto 100px auto;
    margin-top 1180px
    //color #414c41
    color #ffffff
    fontFamily()
    text-align justify
    font-size 28px
    //background rgba(255,255,255,0.2)
    background rgba(127, 210, 120,1)
    word-break break-all
    //background #7fd278
    a
        //color #414c41
        color #ffffff

    .title
        font-size 36px
        font-weight bold
        text-align center
        padding 10px 0 20px
        margin 0
    ul
        padding 0;
        margin 10px 0
        padding-left 20px;
        li
            padding-bottom 6px
</style>
